import React, { useState } from "react";
import {
  AiFillCodeSandboxCircle,
  AiOutlineExclamationCircle,
  AiOutlineMobile,
  AiOutlinePlayCircle,
  AiOutlineProject,
} from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { CgNotes } from "react-icons/cg";
import { FiKey, FiSettings } from "react-icons/fi";
import { GoChecklist } from "react-icons/go";
import { HiOutlineNewspaper } from "react-icons/hi";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdNotificationsOutline,
} from "react-icons/io";
import { LiaStickyNoteSolid } from "react-icons/lia";
import { PiAppWindowBold } from "react-icons/pi";
import { RiOrganizationChart } from "react-icons/ri";
import {
  TbProgressBolt,
  TbReportAnalytics,
  TbUsersGroup,
} from "react-icons/tb";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { updateCases } from "../../reducers/Cases";
import { updateProjects } from "../../reducers/Projects";

const Nav = ({ location }) => {
  const [appPage, setAppPage] = useState(false);
  const [analytics, setAnalytics] = useState(false);
  const [website, setWebsite] = useState(false);
  const [settings, setSettings] = useState(false);

  return (
    <ul className="nav">
      <li className={location.pathname === "/projects" ? "active" : null}>
        <Link to="/projects/list">
          <AiOutlineProject size={24} />
          <p>المشاريع</p>
        </Link>
      </li>

      <li className={location.pathname === "/urgent" ? "active" : null}>
        <Link to="/urgent/list">
          <AiOutlineExclamationCircle size={24} />
          <p>حالات عاجلة</p>
        </Link>
      </li>

      <li className={location.pathname === "/inMemory" ? "active" : null}>
        <Link to="/inMemory">
          <BsPerson size={24} />

          <p>إحياء ذكرى متوفى</p>
        </Link>
      </li>
      {/* app home page */}
      <li
        className="dropDown"
        onClick={() => {
          appPage ? setAppPage(false) : setAppPage(true);
        }}
      >
        <div>
          <AiOutlineMobile size={24} />
          App Home Page
        </div>
        {!appPage && <IoIosArrowDown size={24} />}
        {appPage && <IoIosArrowUp size={24} />}
      </li>
      {appPage && (
        <ul className="submenue">
          <li
            className={
              location.pathname === "/brandEmbassadors" ? "active" : null
            }
          >
            <Link to="/brandEmbassadors">
              <TbProgressBolt size={24} />
              <p>سفراء الخير</p>
            </Link>
          </li>

          <li className={location.pathname === "/banners" ? "active" : null}>
            <Link to="/banners/list">
              <CgNotes size={24} />
              <p>لافتات</p>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/selectedVideos" ? "active" : null
            }
          >
            <Link to="/selectedVideos">
              <AiOutlinePlayCircle size={24} />
              <p>مقاطع فيديو محددة</p>
            </Link>
          </li>
        </ul>
      )}
      <li className={location.pathname === "/ramadanNotes" ? "active" : null}>
        <Link to="/ramadanNotes">
          <LiaStickyNoteSolid size={24} />
          <p>ملاحظات رمضان</p>
        </Link>
      </li>
      <li className={location.pathname === "/shortCodes" ? "active" : null}>
        <Link to="/shortCodes">
          <AiFillCodeSandboxCircle size={24} />
          <p>Short Codes</p>
        </Link>
      </li>
      {/* <li className={location.pathname === "/jalillah" ? "active" : null}>
        <Link to="/jalillah">
          <IoCreateSharp size={24} />
          <p>Create jalillah</p>
        </Link>
      </li> */}
      {/* analytics */}
      <li
        className="dropDown"
        onClick={() => {
          analytics ? setAnalytics(false) : setAnalytics(true);
        }}
      >
        <div>
          <FiSettings size={24} />
          Analytics
        </div>
        {!analytics && <IoIosArrowDown size={24} />}
        {analytics && <IoIosArrowUp size={24} />}
      </li>
      {analytics && (
        <ul className="submenue">
          <li className={location.pathname === "/reports" ? "active" : null}>
            <Link to="/reports">
              <TbReportAnalytics size={24} />
              <p>التقارير</p>
            </Link>
          </li>

          <li className={location.pathname === "/keywords" ? "active" : null}>
            <Link to="/keywords">
              <FiKey />
              <p>الكلمات الدالة</p>
            </Link>
          </li>
        </ul>
      )}
      {/* website */}
      <li
        className="dropDown"
        onClick={() => {
          website ? setWebsite(false) : setWebsite(true);
        }}
      >
        <div>
          <PiAppWindowBold size={24} />
          website
        </div>
        {!website && <IoIosArrowDown size={24} />}
        {website && <IoIosArrowUp size={24} />}
      </li>
      {website && (
        <ul className="submenue">
          <li className={location.pathname === "/teams" ? "active" : null}>
            <Link to="/teams">
              <TbUsersGroup size={24} />
              <p>فرق</p>
            </Link>
          </li>

          <li
            className={location.pathname === "/notification" ? "active" : null}
          >
            <Link to="/notification">
              <IoMdNotificationsOutline size={24} />
              <p> الاشعارات</p>
            </Link>
          </li>
        </ul>
      )}
      <li className={location.pathname === "/ngo" ? "active" : null}>
        <Link to="/ngo">
          <RiOrganizationChart size={24} />
          <p>أخبار المنظمات</p>
        </Link>
      </li>

      {/* settings */}
      <li
        className="dropDown"
        onClick={() => {
          settings ? setSettings(false) : setSettings(true);
        }}
      >
        <div>
          <FiSettings size={24} />
          الاعدادات
        </div>
        {!settings && <IoIosArrowDown size={24} />}
        {settings && <IoIosArrowUp size={24} />}
      </li>
      {settings && (
        <ul className="submenue">
          <li
            className={location.pathname === "/organizations" ? "active" : null}
          >
            <Link to="/organizations">
              <HiOutlineNewspaper size={24} />
              <p>المنظمات</p>
            </Link>
          </li>

          <li className={location.pathname === "/activities" ? "active" : null}>
            <Link to="/activities">
              <GoChecklist size={24} className="fontWeight" />
              <p>الأنشطه</p>
            </Link>
          </li>
          <li className={location.pathname === "/adminuser" ? "active" : null}>
            <Link to="/adminusers">
              <GoChecklist size={24} className="fontWeight" />
              <p>Admin Users</p>
            </Link>
          </li>
          {/* <li>
            <Link to="/">
              <FaRegListAlt size={24} />
              <p>
                preferred <br /> shortcode list
              </p>
            </Link>
          </li> */}

        </ul>
      )}
      <li>
        <a href="/ikhair-daily-report.html" target="_blank" rel="noopener noreferrer">
          <AiOutlineProject size={24} />
          <p>Ikhair Daily Report</p>
        </a>
      </li>
    </ul>
  );
};

export default compose(
  withRouter,
  connect((dispatch) => ({
    updateProjects: () => dispatch(updateProjects()),
    updateCases: () => dispatch(updateCases()),
  }))
)(Nav);
